import React, { Suspense, lazy } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { ProvideAuth, PrivateRoute } from "./components/auth";
import Loading from "./components/loading";
import "./assets/app.css";

const LoginPage = lazy(() => import("./pages/login"));
const HomePage = lazy(() => import("./pages/home"));
const ReportPage = lazy(() => import("./pages/report"));
const NotFoundPage = lazy(() => import("./pages/notfound"));

const App = () => {
    return (
        <ProvideAuth>
            <Router>
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route path="/" exact>
                            <Redirect to="/login" />
                        </Route>
                        <Route path="/blank" exact>
                            <></>
                        </Route>
                        <Route path="/login" exact>
                            <LoginPage />
                        </Route>
                        <PrivateRoute path="/home">
                            <HomePage />
                        </PrivateRoute>
                        <PrivateRoute path="/powerbi/group/:groupId/report/:reportId">
                            <ReportPage />
                        </PrivateRoute>
                        <PrivateRoute>
                            <NotFoundPage />
                        </PrivateRoute>
                    </Switch>
                </Suspense>
            </Router>
        </ProvideAuth>
    );
};

export default App;
