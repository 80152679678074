import React, { useContext, createContext, useState, useEffect } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useReducerAsync, AsyncActionHandlers } from "use-reducer-async";
import { useCookies } from "react-cookie";
import { Buffer } from "buffer";
import axios from "axios";
import GetEnvConfig from "../config";

var config = GetEnvConfig();

type AuthState = {
    isLoggingIn: boolean;
    isLoginFailed: { status: boolean; statusCode?: number };
    isAuthenticated: boolean;
    userName: string | null;
    accessToken: string | null;
    dispatch: React.Dispatch<AuthAction | AuthAsyncAction> | null;
};

const defaultAuthState: AuthState = {
    isLoggingIn: false,
    isLoginFailed: { status: false },
    isAuthenticated: false,
    userName: null,
    accessToken: null,
    dispatch: null,
};

type AuthAction =
    | {
          type: "loginRequest";
      }
    | {
          type: "loginSuccess";
          userName: string;
          accessToken: string;
      }
    | {
          type: "loginFailure";
          statusCode: number;
      }
    | {
          type: "logout";
      };

type AuthAsyncAction =
    | {
          type: "login";
          email: string;
          password: string;
      }
    | {
          type: "loginSession";
          accessToken: string;
          removeCookie: any;
      }
    | {
          type: "logout";
      };

const AuthReducer = (state: AuthState, action: AuthAction): AuthState => {
    switch (action.type) {
        case "loginRequest":
            return {
                ...state,
                isLoggingIn: true,
                isAuthenticated: false,
                userName: null,
            };
        case "loginSuccess":
            return {
                ...state,
                isLoggingIn: false,
                isLoginFailed: { status: false },
                isAuthenticated: true,
                userName: action.userName,
                accessToken: action.accessToken,
            };
        case "loginFailure":
            return {
                ...state,
                isLoggingIn: false,
                isLoginFailed: { status: true, statusCode: action.statusCode },
                isAuthenticated: false,
                userName: null,
            };
        case "logout":
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: false,
                userName: null,
            };
        default:
            throw new Error("no such action type");
    }
};

const asyncActionHandlers: AsyncActionHandlers<
    (state: AuthState, action: AuthAction) => AuthState,
    AuthAsyncAction
> = {
    login: ({ dispatch }: { dispatch: React.Dispatch<AuthAction> }) => async (
        action: AuthAsyncAction
    ) => {
        if (action.type === "login") {
            const loginRequest = async () => {
                try {
                    dispatch({ type: "loginRequest" });
                    const token = Buffer.from(
                        `${action.email}:${action.password}`
                    ).toString("base64");
                    let apiUrl = config.api.url + "api/auth";
                    const response = await axios.get(apiUrl, {
                        headers: {
                            Authorization: `Basic ${token}`,
                        },
                    });
                    dispatch({
                        type: "loginSuccess",
                        userName: response.data.userName,
                        accessToken: response.data.accessToken,
                    });
                } catch (error) {
                    dispatch({
                        type: "loginFailure",
                        statusCode: error.response.status,
                    });
                }
            };
            loginRequest();
        }
    },
    loginSession: ({
        dispatch,
    }: {
        dispatch: React.Dispatch<AuthAction>;
    }) => async (action: AuthAsyncAction) => {
        if (action.type === "loginSession") {
            const loginSessionRequest = async () => {
                try {
                    dispatch({ type: "loginRequest" });
                    let apiUrl = config.api.url + "api/auth/token";
                    const response = await axios.get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${action.accessToken}`,
                        },
                    });
                    dispatch({
                        type: "loginSuccess",
                        userName: response.data.userName,
                        accessToken: action.accessToken,
                    });
                } catch (error) {
                    dispatch({
                        type: "loginFailure",
                        statusCode: error.response.status,
                    });
                    action.removeCookie("accessToken");
                }
            };
            loginSessionRequest();
        }
    },
    logout: ({
        dispatch,
    }: {
        dispatch: React.Dispatch<AuthAction>;
    }) => async () => {
        dispatch({ type: "logout" });
    },
};

const authContext = createContext<AuthState>(defaultAuthState);

export const useAuth = () => {
    return useContext(authContext);
};

export const ProvideAuth = ({ children }: { children: React.ReactNode }) => {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

const useProvideAuth = (): AuthState => {
    const [state, dispatch] = useReducerAsync(
        AuthReducer,
        defaultAuthState,
        asyncActionHandlers
    );
    state.dispatch = dispatch;

    return state;
};

export const PrivateRoute = ({
    children,
    ...rest
}: RouteProps & { children: React.ReactNode }) => {
    const auth = useAuth();
    const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]); // eslint-disable-line
    const [checkAuth, setCheckAuth] = useState<boolean>(false);

    useEffect(() => {
        if (checkAuth) return;
        if (!auth.isAuthenticated && cookies.accessToken) {
            if (!auth.dispatch) return;
            auth.dispatch({
                type: "loginSession",
                accessToken: cookies.accessToken,
                removeCookie: removeCookie,
            });
        }
        setCheckAuth(true);
    }, [auth, cookies.accessToken, removeCookie, checkAuth]);

    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (checkAuth) {
                    if (auth.isAuthenticated) {
                        return children;
                    } else {
                        if (auth.isLoggingIn) {
                            return <></>;
                        } else {
                            return (
                                <Redirect
                                    to={{
                                        pathname: "/login",
                                        state: { from: location },
                                    }}
                                />
                            );
                        }
                    }
                } else {
                }
            }}
        />
    );
};
