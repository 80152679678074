type Config = {
    api: {
        url: string;
    };
};

const GetEnvConfig = (): Config => {
    return {
        api: {
            url: process.env.REACT_APP_API_URL
                ? process.env.REACT_APP_API_URL
                : "",
        },
    };
};

export default GetEnvConfig;
