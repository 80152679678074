import React from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./css/loading.css";

const Loading = () => {
    const classes = useStyles();

    return (
        <div className="circular-progress">
            <div className={classes.root}>
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={40}
                    thickness={4}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={40}
                    thickness={4}
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    top: {
        color: "#ddd",
        animationDuration: "550ms",
        position: "absolute",
        left: 0,
    },
    circle: {
        strokeLinecap: "round",
    },
}));

export default Loading;
